import * as React from 'react';
import { Box, Flex, Heading, useBreakpointValue } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

type Data = {
  screenShotImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  QRCodeImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  arrowImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  scanHereTextImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

export const QRScanSection: React.FC = () => {
  const { screenShotImage, QRCodeImage, arrowImage, scanHereTextImage } =
    useStaticQuery<Data>(
      graphql`
        {
          screenShotImage: file(
            relativePath: { eq: "screenshots/qr-code-employee-device.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 250, layout: CONSTRAINED, quality: 90)
            }
          }
          QRCodeImage: file(
            relativePath: { eq: "features/qr-code-live-test.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 250, layout: CONSTRAINED, quality: 90)
            }
          }
          arrowImage: file(
            relativePath: { eq: "features/arrow-point-left.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 100, layout: CONSTRAINED, quality: 90)
            }
          }
          scanHereTextImage: file(
            relativePath: { eq: "features/scan-here-with-your-phone.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 170, layout: CONSTRAINED, quality: 90)
            }
          }
        }
      `,
    );
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      pt={{ base: '25px', lg: '100px' }}
      pb={{ base: '55px', lg: '140px' }}
      px={{ base: '15px', md: '50px' }}
    >
      <Heading textAlign="center">
        Want to see how it works for your{' '}
        <Box as="span" color="brand.blue">
          team?
        </Box>
      </Heading>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        pt={{ base: '15px', lg: '50px' }}
        gridGap="100px"
        justify="center"
        align="center"
      >
        <Flex align="center" gridGap={{ base: '10px', md: '100px' }}>
          <Box maxWidth="270px" px={{ base: 0, md: 5 }}>
            <GatsbyImage
              alt="QR code employee scan point"
              image={QRCodeImage.childImageSharp.gatsbyImageData}
            />
          </Box>
          <Flex direction="column">
            <Box maxWidth="170px">
              <GatsbyImage
                alt="Scan here"
                image={scanHereTextImage.childImageSharp.gatsbyImageData}
              />
            </Box>
            <Box maxWidth="100px">
              <GatsbyImage
                alt="Arrow pointing left"
                image={arrowImage.childImageSharp.gatsbyImageData}
              />
            </Box>
          </Flex>
        </Flex>
        <Box
          maxWidth="270px"
          px={5}
          display={useBreakpointValue({ base: 'none', md: 'block' })}
        >
          <GatsbyImage
            alt="Canyou employee qr scan screen shot"
            image={screenShotImage.childImageSharp.gatsbyImageData}
            style={{ filter: 'drop-shadow(0px 24px 32px #000F2F14)' }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
