import * as React from 'react';
import { AnswerCard, AnswerCardDetails } from './AnswerCard';
import {
  MdOutlineMotionPhotosPaused,
  MdLockOutline,
  MdOutlineTimer,
} from 'react-icons/md';
import { Flex } from '@chakra-ui/react';

const answerCardDetails: AnswerCardDetails[] = [
  {
    icon: MdOutlineMotionPhotosPaused,
    heading: 'Cancel any time',
    body: 'We never lock you in. We also make it easy to get your data out of Canyou when you are ready to leave.',
  },
  {
    icon: MdOutlineTimer,
    heading: 'Easy Setup',
    body: 'Business setup takes around 10 minutes. Plus we have live support to get you up and running asap.',
  },
  {
    icon: MdLockOutline,
    heading: 'Safe and private',
    body: `We respect your privacy and don't sell your data to anyone. Our security and data policies can be found on our support page.`,
  },
];

export const AnswerObjectionsSection: React.FC = () => (
  <Flex
    my="15px"
    py="50px"
    justify="center"
    align="center"
    gridGap="10"
    px={2}
    direction={{ base: 'column', lg: 'row' }}
  >
    {answerCardDetails.map(({ icon, heading, body }) => (
      <AnswerCard key={heading} body={body} heading={heading} icon={icon} />
    ))}
  </Flex>
);
