import { Heading, List, Flex, Text, Box } from '@chakra-ui/react';
import * as React from 'react';

import { ListItem } from './ListItem';

const CardHeader: React.FC<{
  price: string;
  planName: React.ReactNode | string;
  subHeading: string;
  salesUnit: string;
}> = ({ price, planName, subHeading, salesUnit }) => (
  <>
    <Flex alignItems="center">
      <Text>
        <Box
          as="span"
          fontSize="18px"
          color="brand.blue"
          pr="3px"
          fontWeight="bold"
          verticalAlign="super"
        >
          $
        </Box>
        <Box
          as="span"
          fontSize="52px"
          color="brand.blue"
          letterSpacing="-4px"
          fontWeight="bold"
        >
          {price}
        </Box>
        <Box as="span" fontSize="18px">
          {' '}
          / {salesUnit}
        </Box>
      </Text>
    </Flex>
    <Heading mt="-12px" fontSize="32px">
      {planName}
    </Heading>
    <Text fontWeight="500" fontSize="20px" py={3}>
      {subHeading}
    </Text>
  </>
);

export const PricingCard: React.FC<{
  price: string;
  planName: string | React.ReactNode;
  subHeading: string;
  tags?: JSX.Element[];
  salesUnit: string;
}> = ({ price, planName, subHeading, tags, children, salesUnit }) => {
  return (
    <Flex direction="column" align="center" gridGap={3}>
      {tags && (
        <Flex gridGap={3} flexWrap="wrap" maxW="223px" justify="center">
          {tags}
        </Flex>
      )}
      <Flex
        bg="brand.white"
        borderRadius="80px"
        border="2px solid black"
        boxShadow="-12px 13px 0px 0px #2c6cfb"
        py="28px"
        px={{ base: '36px' }}
        w={['330px', '360px']}
        justify="center"
      >
        <Flex
          direction="column"
          justify="space-between"
          align="center"
          minH="365px"
        >
          <Flex direction="column" justify="center" align="center">
            <CardHeader
              price={price}
              planName={planName}
              subHeading={subHeading}
              salesUnit={salesUnit}
            />
            {children}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const PlanName = () => (
  <Flex justifyContent="center" direction="column" textAlign="center">
    <span>Advanced</span>
    <span style={{ whiteSpace: 'nowrap' }}>E-signature</span>
  </Flex>
);

export const AdvancedEsignPricing: React.FC = () => {
  return (
    <Flex direction="column" mt={[4, 8]}>
      <Heading
        textAlign="center"
        fontSize={['28px', '38px']}
        fontFamily="roboto"
        py={7}
      >
        Add on&apos;s
      </Heading>
      <Flex
        gridGap={10}
        justifyContent="center"
        direction={{ base: 'column', md: 'row' }}
      >
        <PricingCard
          planName={<PlanName />}
          price="9"
          subHeading="per business"
          salesUnit="month"
        >
          <List
            display="flex"
            textAlign="justify"
            flexDirection="column"
            fontSize="18px"
            py={5}
          >
            <ListItem>Multiple Signatories</ListItem>,
            <ListItem>Ordered Signing</ListItem>,
            <ListItem>Multiple fields</ListItem>,
            <ListItem>Unlimited requests</ListItem>,
          </List>
        </PricingCard>
        <PricingCard
          planName="Visa checks"
          price="9"
          subHeading="Bulk discounts available"
          salesUnit="check"
        >
          <List
            display="flex"
            textAlign="justify"
            flexDirection="column"
            fontSize="18px"
            py={5}
          >
            <ListItem>Checks via VEVO</ListItem>,
            <ListItem>Visa status and class</ListItem>,
            <ListItem>Work rights</ListItem>,
            <ListItem>Expiry notifications</ListItem>,
            <ListItem>Electronic audit trail</ListItem>,
          </List>
        </PricingCard>
      </Flex>
    </Flex>
  );
};
