import * as React from 'react';
import { Heading, Flex, List } from '@chakra-ui/react';
import { ListItem } from './ListItem';

export const Inclusions: React.FC = () => {
  return (
    <Flex direction="column" mt={[4, 8]}>
      <Heading
        textAlign="center"
        size="medium"
        fontFamily="roboto"
        mb={['20px', '64px']}
      >
        All prices include GST
      </Heading>
      <Heading
        textAlign="center"
        fontSize={['32px', '44px']}
        fontFamily="roboto"
      >
        All plans include unlimited
      </Heading>
      <Flex justify="center">
        <Flex direction={['column', 'row']} pt={[2, 10]} gridGap={[0, 6]}>
          <List
            display="flex"
            textAlign="justify"
            flexDirection="column"
            fontSize="18px"
          >
            <ListItem>Employees</ListItem>
            <ListItem>Customers / clients</ListItem>
            <ListItem>Suppliers</ListItem>
            <ListItem>Document storage</ListItem>
            <ListItem>Document capture</ListItem>
            <ListItem>Licences &amp; certifications</ListItem>
          </List>
          <List
            display="flex"
            textAlign="justify"
            flexDirection="column"
            fontSize="18px"
          >
            <ListItem>E-signature (single signatory)</ListItem>
            <ListItem>Custom forms</ListItem>
            <ListItem>3rd party integrations</ListItem>
            <ListItem>Videos</ListItem>
            <ListItem>Workflows</ListItem>
          </List>
        </Flex>
      </Flex>
    </Flex>
  );
};
