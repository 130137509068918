import { Flex, Icon, Text } from '@chakra-ui/react';
import * as React from 'react';
import { IconType } from 'react-icons';

export type AnswerCardDetails = {
  icon: IconType;
  heading: string;
  body: string;
};

type AnswerCardProps = AnswerCardDetails;

export const AnswerCard: React.FC<AnswerCardProps> = ({
  body,
  heading,
  icon,
}) => (
  <Flex
    direction="column"
    justify="flex-start"
    align="center"
    pt="50px"
    bg="brand.white"
    boxShadow="0px 34px 84px #193F9214"
    borderRadius="32px"
    maxW="390px"
    px="30px"
    height="360px"
  >
    <Flex bg="brand.blue" p="15px" mb="27px" borderRadius="50%">
      <Icon as={icon} h="60px" w="60px" color="brand.white" />
    </Flex>
    <Text
      color="brand.blue"
      fontWeight="bold"
      fontSize="20px"
      textAlign="center"
      mb="11px"
    >
      {heading}
    </Text>
    <Text textAlign="center">{body}</Text>
  </Flex>
);
