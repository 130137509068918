import * as React from 'react';
import { Box, Flex, Icon, List, Tag, Text } from '@chakra-ui/react';
import { PricingCard } from './PricingCard';
import { ListItem } from './ListItem';
import { AiOutlineUser } from 'react-icons/ai';

const sharedFeatures = [
  <ListItem>Employee onboarding</ListItem>,
  <ListItem>Client / supplier onboarding</ListItem>,
  <ListItem>Identity verification</ListItem>,
  <ListItem>Licence &amp; cert management</ListItem>,
];

const FeatureList: React.FC<{ hasSupport?: boolean }> = ({ hasSupport }) => (
  <List
    display="flex"
    textAlign="justify"
    flexDirection="column"
    fontSize="18px"
    pt={10}
  >
    {sharedFeatures}
    {hasSupport && <ListItem>Australian support 🇦🇺</ListItem>}
  </List>
);

const AdvisorFeatureList = () => (
  <List
    display="flex"
    textAlign="justify"
    flexDirection="column"
    fontSize="18px"
  >
    <ListItem>Unlimited businesses</ListItem>
  </List>
);

export const PricingCardList: React.FC = () => (
  <Flex
    gridGap={10}
    alignItems="self-end"
    flexWrap="wrap"
    justify="center"
    px={[2, 0]}
  >
    <PricingCard
      price="0"
      planName="Free"
      subHeading="Add 2 users per month"
      features="Unlimited use of all features"
      cta="Get started"
    >
      <FeatureList />
      <Text mt={7} color="gray.600">
        <Icon w={5} h={5} as={AiOutlineUser} mb={-1} /> 1 admin team member
      </Text>
    </PricingCard>
    <PricingCard
      price="30"
      planName="Business"
      subHeading="1 x single business"
      features="Unlimited use of all features"
      cta="Try for free"
      tags={[
        <Tag bg="#F2D260" borderRadius="full" color="black">
          Small - Medium Business
        </Tag>,
      ]}
    >
      <FeatureList hasSupport />
    </PricingCard>
    <PricingCard
      price="39"
      planName="Advisor"
      subHeading="Multi-business"
      features="Everything in Business plan"
      cta="Try for free"
      tags={[
        <Tag bg="#FC8181" borderRadius="full" color="black">
          Bookkeepers
        </Tag>,
        <Tag bg="#FC8181" borderRadius="full" color="black">
          HR Consultants
        </Tag>,
        <Tag bg="#FC8181" borderRadius="full" color="black">
          Accountants
        </Tag>,
      ]}
    >
      <Box as="span" fontSize="28px">
        +
      </Box>
      <AdvisorFeatureList />
      <Text mt={10} color="gray.600">
        <Icon w={5} h={5} as={AiOutlineUser} mb={-1} /> 10 admin team members
      </Text>
      <Text color="gray.600">$3.90 per extra admin</Text>
    </PricingCard>
  </Flex>
);
