import * as React from 'react';
import { Flex, Heading, Box, Button, Text, Icon } from '@chakra-ui/react';
import { BsChatRightText } from 'react-icons/bs';
import { FiPhoneCall } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { getConfig } from '../../config';

const config = getConfig();

const IconCTA: React.FC<{ icon: IconType }> = ({ icon }) => (
  <Flex bg="brand.blue" p="15px" mb="27px" borderRadius="25px" justify="center">
    <Icon as={icon} h="40px" w="40px" color="brand.white" />
  </Flex>
);

export const FinalCTA: React.FC = () => (
  <>
    <Flex justify="center" mb="20px" px={2}>
      <Flex
        direction="column"
        justify="center"
        align="center"
        bg="brand.white"
        px={{ base: '20px', md: '60px' }}
        py="70px"
        boxShadow="0px 4px 27px #193F9221"
        rounded="md"
      >
        <Heading fontSize="3.75em" textAlign="center" mb="45px">
          <Box as="span">Try </Box>
          <Box as="span" color="brand.blue">
            Canyou.{' '}
          </Box>
          <Box as="span">for free</Box>
        </Heading>
        <Box mb="10px">
          <Button as="a" href={`${config.platformUrl}/signup`} size="lg">
            Try for free
          </Button>
        </Box>
        <Text fontSize="20px" textAlign="center">
          No credit card required
        </Text>
      </Flex>
    </Flex>
    <Flex
      direction="column"
      justify="center"
      align="center"
      mt="50px"
      mb="70px"
    >
      <Text fontSize="30px" textAlign="center" mb="45px">
        Still have questions?
      </Text>
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        align="center"
        justify="center"
        gridGap={{ base: '30px', lg: '60px' }}
      >
        <Flex direction="column" justify="center" align="center">
          <IconCTA icon={FiPhoneCall} />
          <Box>
            <Button
              size="lg"
              variant="outline"
              as="a"
              target="_blank"
              rel="noreferrer noopener"
              href={`${config.demoBookingUrl}`}
            >
              Book a demo
            </Button>
          </Box>
        </Flex>
        <Flex direction="column" justify="center" align="center">
          <IconCTA icon={BsChatRightText} />
          <Box>
            <Button
              size="lg"
              variant="outline"
              onClick={() => {
                if (window && window.Intercom) {
                  window.Intercom('show');
                }
              }}
            >
              Start a live chat
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  </>
);
