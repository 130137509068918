import * as React from 'react';
import { Flex, Text, Box, Button, Heading } from '@chakra-ui/react';
import { getConfig } from '../../config';

const config = getConfig();

const CardHeader: React.FC<{
  price: string;
  planName: string;
  subHeading: string;
  features: string;
}> = ({ price, planName, subHeading, features }) => (
  <>
    <Flex alignItems="center">
      <Text>
        <Box
          as="span"
          fontSize="18px"
          color="brand.blue"
          pr="3px"
          fontWeight="bold"
          verticalAlign="super"
        >
          $
        </Box>
        <Box
          as="span"
          fontSize="52px"
          color="brand.blue"
          letterSpacing="-4px"
          fontWeight="bold"
        >
          {price}
        </Box>
        <Box as="span" fontSize="18px">
          {' '}
          / month
        </Box>
      </Text>
    </Flex>
    <Heading mt="-12px" fontSize="32px">
      {planName}
    </Heading>
    <Text fontWeight="500" fontSize="20px" py={3}>
      {subHeading}
    </Text>
    <Text
      fontSize="28px"
      pt={2}
      textAlign="center"
      fontWeight="500"
      width="200px"
      lineHeight={1.2}
    >
      {features}
    </Text>
  </>
);

export const PricingCard: React.FC<{
  price: string;
  planName: string;
  subHeading: string;
  features: string;
  cta: string;
  tags?: JSX.Element[];
}> = ({ price, planName, subHeading, features, cta, tags, children }) => {
  return (
    <Flex direction="column" align="center" gridGap={3}>
      {tags && (
        <Flex gridGap={3} flexWrap="wrap" maxW="223px" justify="center">
          {tags}
        </Flex>
      )}
      <Flex
        bg="brand.white"
        borderRadius="80px"
        border="2px solid black"
        boxShadow="-12px 13px 0px 0px #2c6cfb"
        py="28px"
        px={{ base: '36px' }}
        minH="590px"
        minW={['330px', '360px']}
        justify="center"
      >
        <Flex direction="column" justify="space-between" align="center">
          <Flex direction="column" justify="center" align="center">
            <CardHeader
              price={price}
              planName={planName}
              subHeading={subHeading}
              features={features}
            />
            {children}
          </Flex>
          <Button as="a" href={`${config.platformUrl}/signup`} my={2} px="80px">
            {cta}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
