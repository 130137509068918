import * as React from 'react';
import { ListItem as ChakraListItem, Icon } from '@chakra-ui/react';
import { IoMdCheckmarkCircle } from 'react-icons/io';

export const ListItem: React.FC = ({ children }) => (
  <ChakraListItem>
    <Icon
      position="relative"
      top="3px"
      left="-8px"
      as={IoMdCheckmarkCircle}
      color="#3CD207"
      h={6}
      w={6}
    />
    {children}
  </ChakraListItem>
);
