import { Text, Heading, Box } from '@chakra-ui/react';
import * as React from 'react';
import { Inclusions } from './Inclusions';
import { PricingCardList } from './PricingCardList';
import { AdvancedEsignPricing } from './AdvancedEsignPricing';

export const PricingSection: React.FC = () => {
  return (
    <Box bg="brand.egg" pt={{ base: '40px', md: '80px' }} pb="30px">
      <Heading textAlign="center">
        Simple affordable{' '}
        <Box as="span" color="brand.blue">
          plans
        </Box>
      </Heading>
      <Text
        px={2}
        py={8}
        alignSelf="center"
        textAlign="center"
        fontSize={['28px', '33px']}
        letterSpacing="1.65px"
      >
        Get started for free,
        <br /> no credit card required
      </Text>
      <PricingCardList />
      <AdvancedEsignPricing />
      <Inclusions />
    </Box>
  );
};
